import "core-js/stable";

import "regenerator-runtime/runtime";
import $ from "jquery";

import(/* webpackChunkName: 'bootstraping5m' */ "bootstrap5");

$(() => {
  $(document).on("change", "#locale_switcher", (event) => {
    $(event.currentTarget).parent("form").submit();
  });
});
